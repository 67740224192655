.pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}
ul {
    list-style: none;
    padding: 0;
}
ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid #E5E8EB;
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 1rem;
    flex-wrap: wrap;
    cursor: pointer;
}
ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
}
ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
}
ul.pagination li a {
    text-decoration: none;
    color: #66BB6A;
    font-size: 1.2rem;
}
ul.pagination li.active a {
    color: #FFF;
}
ul.pagination li.active {
    background-color: #66BB6A;
}
ul.pagination li a:hover,
ul.pagination li a.active {
    color: #4CAF50;
}
.page-selection {
    width: 48px;
    height: 30px;
    color: #66BB6A;
}